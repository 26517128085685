//Routes
export enum ROUTES {
  ROOT = "/",
  MATCHES = "/matches",
  MATCH_DETAILS = "/match-details",
  TEAM_MATCHES = "/team-matches",
  FAVORITES = "/favorite-matches",
  GROUPS = "/table",
  TOURNAMENT = "/tournament",
  KNOCK_OUT = "/knockout",
  SIGN_IN = "/sign-in",
  ADMIN = "/admin",
  ADMIN_MATCHES = "/admin/matches",
  ADMIN_GENERATE_TOURNAMENT = "/admin/generate-tournament",
  ADMIN_CREATE_MATCH = "/admin/create-match",
  ADMIN_TABLE = "/admin/table",
  ADMIN_PULJER = "/admin/puljer",
  ADMIN_CREATE_PULJE = "/admin/create-pulje",
  ADMIN_GROUPS = "/admin/groups",
  ADMIN_CREATE_GROUP = "/admin/create-group",
  ADMIN_TEAMS = "/admin/teams",
  ADMIN_CREATE_TEAM = "/admin/create-team",
  ADMIN_LOCATIONS = "/admin/locations",
  ADMIN_CREATE_LOCATION = "/admin/create-location",
  ADMIN_SETTINGS = "/admin/settings",
  ADMIN_SEEDING = "/admin/seeding",
  ADMIN_KNOCK_OUT = "/admin/knockout",
  SUPER_ADMIN = "/super-admin",
  SUPER_ADMIN_TOURNAMENTS = "/super-admin/tournaments",
  SUPER_ADMIN_CREATE_TOURNAMENT = "/super-admin/create-tournament",
  SUPER_ADMIN_USERS = "/super-admin/users",
  SUPER_ADMIN_CREATE_USERS = "/super-admin/create-user",
}
